<template>
  <div>
    <div class="row mt-2 mx-2">
      <div class="col-12">
        <v-card elevation="1">
            <v-toolbar flat outlined color="#f2f2f2">
              <v-toolbar-title>
                <span>
                  <v-icon size="40" color="blue">
                    mdi-shield-key-outline
                  </v-icon>
                </span>
                <span class="blue--text text-h5 font-weight-bold">
                  Users assigned to a role
                </span>
              </v-toolbar-title>
            </v-toolbar>
            <v-data-table :headers="headers" :items="usersAssignedToRole" :search="search">
              <template v-slot:[`item.SN`]="{ item }">
                {{ usersAssignedToRole.indexOf(item) + 1 }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu app offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      slot="activator"
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item link @click="unAssignRole(item.pk)">
                      <v-list-item-title>
                        <span>
                          <v-icon color="red">mdi-progress-alert</v-icon>
                        </span>
                        <span class="red--text mx-5">
                          Unassign role
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
export default {

    data:()=>({
        search: '',
            headers: [
      {
        text: "SN",
        align: "start",
        sortable: false,
        value: "SN",
      },
      { text: "First name", value: "firstName" },
      { text: "Last name", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    }),
    async mounted(){
        await this.$store.dispatch('fetchUsersAssignedToRole', {roleUniqueId: this.$route.params.roleUniqueId});
    },
    computed:{
        ...mapGetters({
            usersAssignedToRole: "getUsersAssignedToRole"
        })
    },
    methods:{
        unAssignRole(userpk){
        let data = {
                userPk: userpk,
                roleUniqueId: this.$route.params.roleUniqueId
            }

        this.$store.dispatch('unassignRole', data);
        }
    }
    }
</script>
