var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row mt-2 mx-2"},[_c('div',{staticClass:"col-12"},[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-toolbar',{attrs:{"flat":"","outlined":"","color":"#f2f2f2"}},[_c('v-toolbar-title',[_c('span',[_c('v-icon',{attrs:{"size":"40","color":"blue"}},[_vm._v(" mdi-shield-key-outline ")])],1),_c('span',{staticClass:"blue--text text-h5 font-weight-bold"},[_vm._v(" Users assigned to a role ")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.usersAssignedToRole,"search":_vm.search},scopedSlots:_vm._u([{key:"item.SN",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.usersAssignedToRole.indexOf(item) + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"app":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","color":"primary","icon":""},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.unAssignRole(item.pk)}}},[_c('v-list-item-title',[_c('span',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-progress-alert")])],1),_c('span',{staticClass:"red--text mx-5"},[_vm._v(" Unassign role ")])])],1)],1)],1)]}}],null,true)})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }